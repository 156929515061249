import React from "react";
import BannerBook from "../Components/bannerBook";
import From from "../Components/From";
const Contact = () => {
  return (
    <>
      <BannerBook/>
      <From/>
    </>
  );
};

export default Contact;
