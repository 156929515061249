import React from "react";

const bannerBook = () => {
  return (
    <div className="bg-Book mt-100">
     <div className="text-center">
     <h1 className="text-light h1">Book Us</h1>
      <p className="text-light">or inquire below to setup an in person meeting</p>
     </div>
    </div>
  );
};

export default bannerBook;
